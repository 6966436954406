/* src/styles/blog.css */

.blog-title {
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 0.5rem;
  text-align: center;
}

.featured-post img {
  height: 400px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.featured-post img:hover {
  transform: scale(1.05);
}

.blog-card {
  margin-bottom: 20px;
}

.blog-card img {
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.blog-card img:hover {
  transform: scale(1.05);
}

.blog-excerpt {
  height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blog-detail img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.about-card, .real-time-news-card {
  animation: fadeIn 1s ease-in-out;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Contenedor del título y la imagen para asegurar que el título está sobre la imagen */
.header-container {
  position: relative;
}

/* Estilo para el contenedor del título "Nuestro Blog" */
.blog-title-container {
  position: absolute;
  top: 65%; /* Ajusta la posición vertical según sea necesario */
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center; /* Centra el texto horizontalmente */
}

/* Estilo para el título "Nuestro Blog" */
.blog-title {
  background-color: rgba(95, 7, 7, 0.5);
  padding: 88px; /* Ajusta el padding según tu preferencia */
  font-size: 4.5rem; /* Ajusta el tamaño de la fuente según tus necesidades */
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Media Query para dispositivos móviles */
@media (max-width: 768px) {
  .blog-title {
    font-size: 2rem; /* Ajusta el tamaño de la fuente para pantallas más pequeñas */
    padding: 40px 20px; /* Ajusta el padding para que se vea bien en dispositivos móviles */
  }

  .blog-title-container {
    top: 50%; /* Ajusta la posición vertical para móviles */
  }
}

@media (max-width: 480px) {
  .blog-title {
    font-size: 2.5rem; /* Ajusta aún más el tamaño de la fuente para pantallas muy pequeñas */
    padding: 102px 46px; /* Ajusta el padding para dispositivos muy pequeños */
  }

  .blog-title-container {
    top: 66%; /* Ajusta la posición vertical para dispositivos muy pequeños */
  }
}