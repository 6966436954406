/* src/styles/about.css */
.small-somos{
width: 350px;
}
/* MISON Y VISON */
.mission-vision-section {
  display: flex;
  align-items: center;
}

.full-width-image {
  max-width: 100%;
  border-radius: 15px; 
  transition: transform 0.4s ease-in-out; 
  
  width: 350px;/* Ajusta esto según tu preferencia */
}

.mission-title, .vision-title {
  color: #4CAF50; /* Color verde */
  font-weight: bold;
}

.mission-title {
  margin-top: 0;
}

.vision-title {
  margin-top: 30px;
}

.mission-text, .vision-text {
  text-align: justify;
  font-size: 1.3rem;
}



/* FIN */


.full-width-image:hover {
  transform: translateX(10px) translateY(10px); /* Mueve la imagen hacia la derecha y hacia abajo */
}

.rounded-shadow {
  width: 400px;
}

.quienes-somos-section {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

h2 {
  color: #28a745;
}

.small-image:hover {
  transform: scale(1.05);
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.shadow-sm {
  width: 450px;
  margin-left: 90px;
}


.thumbnail-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.thumbnail {
  width: 80px;
  height: 60px;
  margin: 0 5px;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border 0.3s;
}
.section-trabajo{
  text-align: center;
  text-size-adjust: bold;
  color: #28a745;
}

.section-ti{
  color: #28a745;
}


/* Contenedor de la imagen para asegurar que es el punto de referencia */
.header-container {
  position: absolute;
}

/* Estilo para el título "Sobre Nosotros" */
.about-title-container {
  position: absolute;
  top: 65%; /* Ajusta la posición vertical según sea necesario */
  left: 50%;
  transform: translate(-50%, -50%);
  width: 110%; /* Asegura que el contenedor abarque toda la imagen */
  text-align: center; /* Centra el texto horizontalmente */
}

.about-title {
  background-color: rgba(95, 7, 7, 0.5);
  padding: 82px; /* Ajusta el padding según tu preferencia */
  font-size: 4.5rem; /* Ajusta el tamaño de la fuente según tus necesidades */
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}


/* Media Query para dispositivos móviles */
@media (max-width: 768px) {
  .about-title {
    font-size: 2rem; /* Ajusta el tamaño de la fuente para pantallas más pequeñas */
    padding: 40px 20px; /* Ajusta el padding para que se vea bien en dispositivos móviles */
  }

  .about-title-container {
    top: 50%; /* Ajusta la posición vertical para móviles */
  }
}

@media (max-width: 480px) {
  .about-title {
    font-size: 2.5rem; /* Ajusta aún más el tamaño de la fuente para pantallas muy pequeñas */
    padding: 99px 46px; /* Ajusta el padding para dispositivos muy pequeños */
  }

  .about-title-container {
    top: 66%; /* Ajusta la posición vertical para dispositivos muy pequeños */
  }
}