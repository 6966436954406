/* src/styles/footer.css */
.footer {
  background-color: #4480A6;
  color: white;
  padding: 20px 0;
  text-align: center;
}

.footer-logo {
  text-align: center;
}

.footer-logo-wrapper {
  display: inline-block;
  background-color: white; /* Fondo blanco */
  border-radius: 50%; /* Hace que el fondo sea circular */
}

.footer-logo-img {
  width: 50px;
  height: auto;
}

.footer-address, .footer-contact {
  text-align: center;
}

.footer-address i {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.footer-contact h5 {
  margin-bottom: 10px;
}
