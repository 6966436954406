/* src/styles/specialists.css */

.specialists-header {
  position: relative;
  text-align: center;
  color: white;
  margin-bottom: 60px;
}

.header-image {
  width: 100%;
  height: 350px; /* Ajusta la altura según tus necesidades */
  object-fit: cover;
  opacity: 0.7;
}

.specialists-container {
  margin-top: 50px;
}

.specialist-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.specialist-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.card-image-wrapper {
  position: relative;
  width: 100%;
  padding-top: 75%; /* Aspect ratio 4:3 */
  overflow: hidden;
  border-radius: 5px 5px 0 0;
}

.card-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.card-image-wrapper:hover .card-image {
  transform: scale(1.1);
}

.specialist-description {
  margin-top: 10px;
}

/* Contenedor del título y la imagen para asegurar que el título está sobre la imagen */
.header-container {
  position: relative;
}

/* Estilo para el contenedor del título "Nuestros Especialistas" */
.specialists-title-container {
  position: absolute;
  top: 65%; /* Ajusta la posición vertical según sea necesario */
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center; /* Centra el texto horizontalmente */
}

/* Estilo para el título "Nuestros Especialistas" */
.specialists-title {
  background-color: rgba(95, 7, 7, 0.5);
  padding: 82px; /* Ajusta el padding según tu preferencia */
  font-size: 4.5rem; /* Ajusta el tamaño de la fuente según tus necesidades */
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Media Query para dispositivos móviles */
@media (max-width: 768px) {
  .specialists-title {
    font-size: 2rem; /* Ajusta el tamaño de la fuente para pantallas más pequeñas */
    padding: 40px 20px; /* Ajusta el padding para que se vea bien en dispositivos móviles */
  }

  .specialists-title-container {
    top: 50%; /* Ajusta la posición vertical para móviles */
  }
}

@media (max-width: 480px) {
  .specialists-title {
    font-size: 2.5rem; /* Ajusta aún más el tamaño de la fuente para pantallas muy pequeñas */
    padding: 75px 46px; /* Ajusta el padding para dispositivos muy pequeños */
  }

  .specialists-title-container {
    top: 66%; /* Ajusta la posición vertical para dispositivos muy pequeños */
  }
}

