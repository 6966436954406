/* src/styles/videoCarousel.css */
.video-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .video-carousel-item {
    display: flex;
    justify-content: center;
  }
  
  .embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 40.25%; /* 16:9 ratio */
  }
  
  .embed-responsive-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  