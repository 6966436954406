/* src/styles/contact.css */

/* Estilos generales */
.contact-details {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.contact-methods-container {
  display: flex;
  align-items: center;
}

.contact-methods {
  width: 100%;
}

.contact-method {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 10px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.contact-method a {
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
}

.contact-method i {
  font-size: 1.5rem;
  color: #007bff;
  margin-right: 15px;
}

.contact-method:hover {
  transform: translateY(-5px);
}

.highlight {
  color: #007bff;
}


/* Estilos para la imagen y el texto superpuesto */
.image-with-text {
  position: relative; /* Permite la superposición del texto sobre la imagen */
  display: inline-block; 
  width: 100%; /* Asegura que el contenedor cubra todo el ancho de la imagen */
}

.image-with-text img {
  width: 100%; /* La imagen ocupa todo el ancho del contenedor */
  border-radius: 8px; /* Mantiene los bordes redondeados */
}

.image-text {
  position: absolute; 
  bottom: 10px; /* Ajusta según sea necesario para la altura deseada desde la parte inferior */
  left: 10px; /* Ajusta para alineación a la izquierda */
  background-color: rgba(0, 0, 0, 0.6); /* Fondo oscuro detrás del texto */
  color: white; /* Color del texto */
  padding: 5px 10px; /* Espacio alrededor del texto */
  text-align: left; /* Alinea el texto a la izquierda */
  font-style: italic; /* Aplica cursiva al texto */
  border-radius: 5px; /* Bordes redondeados del fondo oscuro */
}


/* Estilos para los iconos sociales */
.social-icons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.social-icons a {
  margin: 0 40px;
  font-size: 2rem;
  color: #007bff;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #0056b3;
}

/* Sede Lima */
.inspirational-quote {
  font-style: italic;
  color: #444;
  padding: 10px;
  border-left: 4px solid #007bff; /* Línea decorativa a la izquierda */
  margin-top: 20px;
  text-align: center;
}



/* Contenedor del título y la imagen para asegurar que el título está sobre la imagen */
.header-container {
  position: relative;
}

/* Estilo para el contenedor del título "Contactos" */
.contact-title-container {
  position: absolute;
  top: 65%; /* Ajusta la posición vertical según sea necesario */
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120%;
  text-align: center; /* Centra el texto horizontalmente */
}

/* Estilo para el título "Contactos" */
.contact-title {
  background-color: rgba(95, 7, 7, 0.5);
  padding: 82px; /* Ajusta el padding según tu preferencia */
  font-size: 4.5rem; /* Ajusta el tamaño de la fuente según tus necesidades */
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}


/* Media Query para dispositivos móviles */
@media (max-width: 768px) {
  .contact-title {
    font-size: 2rem; /* Ajusta el tamaño de la fuente para pantallas más pequeñas */
    padding: 40px 20px; /* Ajusta el padding para que se vea bien en dispositivos móviles */
  }

  .contact-title-container {
    top: 50%; /* Ajusta la posición vertical para móviles */
  }
}

@media (max-width: 480px) {
  .contact-title {
    font-size: 2.5rem; /* Ajusta aún más el tamaño de la fuente para pantallas muy pequeñas */
    padding: 99px 46px; /* Ajusta el padding para dispositivos muy pequeños */
  }

  .contact-title-container {
    top: 66%; /* Ajusta la posición vertical para dispositivos muy pequeños */
  }
}

