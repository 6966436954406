/* Estilo del fondo */
.services-background {
  background-color: #f8f9fa;
  padding-bottom: 50px;
}

/* Estilo para las tarjetas de servicio */
.service-card {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.service-card-image {
  position: relative;
}

.service-card-image img {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease, filter 0.3s ease;
}

.service-card:hover .service-card-image img {
  filter: brightness(1.4);
  transform: translateY(-10px);
}

.service-card-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  padding: 20px;
  font-size: 1.5rem;
  text-transform: uppercase;
  transition: background 0.3s ease, font-size 0.3s ease, opacity 0.3s ease;
}

.service-card-title h3 {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.service-card-title h3 i {
  font-size: 3rem;
  margin-bottom: 10px;
  color: #FFD700; /* Color dorado por defecto para el icono */
}

.service-card:hover .service-card-title {
  opacity: 0;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent; /* Cambiamos el fondo a transparente */
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-card:hover .overlay {
  opacity: 1;
}

.overlay-content {
  text-align: center;
}

.overlay-content .btn {
  margin: 5px;
}

/* Sección de Testimonios */
.testimonials-section {
  padding: 50px 0;
}

.section-title {
  margin-bottom: 40px;
  font-size: 2.5rem;
  font-weight: bold;
}

.testimonial-card {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.testimonial-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.testimonial-card p {
  font-style: italic;
  margin-bottom: 10px;
}

.testimonial-card h5 {
  font-weight: bold;
}

/* Sección de Contacto con un Asesor */
.advisor-contact-section {
  background: #f8f9fa;
  padding: 50px 0;
}

.advisor-contact-card {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.advisor-contact-card h3 {
  margin-bottom: 20px;
}

.advisor-contact-card p {
  margin-bottom: 20px;
}

.advisor-contact-card .btn {
  margin-top: 20px;
}


/* Contenedor del título y la imagen para asegurar que el título está sobre la imagen */
.header-container {
  position: relative;
}

/* Estilo para el contenedor del título "Nuestros Servicios" */
.services-title-container {
  position: absolute;
  top: 65%; /* Ajusta la posición vertical según sea necesario */
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center; /* Centra el texto horizontalmente */
}

/* Estilo para el título "Nuestros Servicios" */
.services-titles {
  background-color: rgba(95, 7, 7, 0.5);
  padding: 82px; /* Ajusta el padding según tu preferencia */
  font-size: 4.5rem; /* Ajusta el tamaño de la fuente según tus necesidades */
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Media Query para dispositivos móviles */
@media (max-width: 768px) {
  .services-titles {
    font-size: 2rem; /* Ajusta el tamaño de la fuente para pantallas más pequeñas */
    padding: 40px 20px; /* Ajusta el padding para que se vea bien en dispositivos móviles */
  }

  .services-titles-container {
    top: 50%; /* Ajusta la posición vertical para móviles */
  }
}

@media (max-width: 480px) {
  .services-titles {
    font-size: 2.5rem; /* Ajusta aún más el tamaño de la fuente para pantallas muy pequeñas */
    padding: 80px 48px; /* Ajusta el padding para dispositivos muy pequeños */
  }

  .services-titles-container {
    top: 66%; /* Ajusta la posición vertical para dispositivos muy pequeños */
  }
}