/* src/styles/specialistDetail.css */

.specialist-detail-background {
  position: relative;
  z-index: 1; /* Asegúrate de que el contenido esté por encima del fondo animado */
}

.specialist-detail-background::before, .specialist-detail-background::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 150%;
  height: 100%;
  background: linear-gradient(45deg, rgba(75, 114, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(75, 114, 255, 0.2) 50%, rgba(75, 114, 255, 0.2) 75%, transparent 75%, transparent);
  animation: moveBackground 25s linear infinite;
}

.specialist-detail-background::after {
  background: linear-gradient(45deg, rgba(255, 188, 0, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 188, 0, 0.2) 50%, rgba(255, 188, 0, 0.2) 75%, transparent 75%, transparent);
  animation-direction: reverse;
}

@keyframes moveBackground {
  from {
    transform: translateX(0) translateY(0);
  }
  to {
    transform: translateX(-50%) translateY(-50%);
  }
}

.specialist-detail {
  margin-top: 200px;
  text-align: left;
  position: relative;
  z-index: 1; /* Asegúrate de que el contenido esté por encima del fondo animado */
}

.text-section {
  padding: 20px;
  margin-top: -100px;
}

.specialist-title {
  color: #4b72ff;
  font-weight: bold;
}

.specialist-role {
  color: #ffbc00;
  font-size: 2.5rem;
}

.specialist-description {
  margin: 30px 0;
}

.image-wrapper {
  position: relative;
  width: 100%;
  padding-top: 100%; /* Aspect ratio 1:1 */
}

.specialist-image {
  position: absolute;
  top: 30px;
  left: 33px;
  width: 90%;
  height: 90%;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

ul {
  padding-left: 20px;
}

ul li {
  margin-bottom: 5px;
}

h3 {
  margin-top: 20px;
  color: #4b72ff;
}

.contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.contact-icon-email {
  font-size: 2rem; /* Aumenta el tamaño del ícono */
  margin-right: 15px;
  color: #4b72ff; /* Color para el ícono de correo */
}

.contact-icon-phone {
  font-size: 2rem; /* Aumenta el tamaño del ícono */
  margin-right: 15px;
  color: #ffbc00; /* Color para el ícono de teléfono */
}

.contact-item p {
  margin: 0;
  font-size: 1.2rem; /* Ajusta el tamaño del texto */
}

.button-container {
  margin-top: 20px;
}

.background-shape {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  z-index: -1;
}


/* Estilos para dispositivos móviles */
@media (max-width: 768px) {
  .specialist-detail {
    margin-top: 150px;
  }

  .specialist-role {
    font-size: 2rem;
  }

  .specialist-description {
    margin: 20px 0;
  }

  .specialist-image {
    top: 20px;
    left: 25px;
    width: 85%;
    height: 85%;
  }

  .contact-icon-email,
  .contact-icon-phone {
    font-size: 1.5rem; /* Tamaño del ícono reducido */
  }

  .contact-item p {
    font-size: 1rem; /* Ajuste del tamaño del texto */
  }
}

@media (max-width: 480px) {
  .specialist-detail {
    margin-top: 200px;
  }

  .specialist-role {
    font-size: 1.5rem;
  }

  .specialist-description {
    margin: 15px 0;
  }

  .specialist-image {
    top: 19px;
    left: 19px;
    width: 90%;
    height: 90%;
  }

  .contact-icon-email,
  .contact-icon-phone {
    font-size: 1.2rem; /* Tamaño del ícono reducido */
  }

  .contact-item p {
    font-size: 0.9rem; /* Ajuste del tamaño del texto */
  }
}