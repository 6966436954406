.service-detail-container {
  padding: 0;
}

.service-content {
  padding: 20px;
}

.section-title {
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-top: 20px;
  color: #333;
  font-size: 1.5rem;
  text-transform: uppercase;
}

.section-title .section-icon {
  margin-right: 10px;
}

.points-list,
.detailed-procedure-list,
.benefits-list {
  margin-left: 3rem; /* Mueve las listas más a la derecha */
}

.portfolio-section {
  padding: 20px;
  margin-top: 20px;
}

.portfolio-image-container {
  text-align: center;
}

.carousel-service {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px; /* Ajusta la altura del contenedor del carrusel según tus necesidades */
}

.carousel-service img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Contain asegura que la imagen se ajuste dentro del contenedor sin recortarse */
}

.service-button {
  margin-bottom: 10px;
  width: 100%;
  background-color: #004085;
  border: none;
  color: white;
  padding: 10px;
  border-radius: 8px;
  font-weight: bold;
  transition: background-color 0.3s;
  text-align: left;
  font-size: 0.9rem; /* Ajusta el tamaño de la fuente */
}

.service-button:hover {
  background-color: #0056b3;
}

.btn-consult {
  background-color: #28a745;
  border: none;
  margin-top: 20px;
  padding: 10px;
  font-size: 0.9rem; /* Ajusta el tamaño de la fuente */
  width: 50%; /* Ajusta el ancho del botón */
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.info-card {
  margin-top: 20px;
}

.info-card .card-title {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

.info-card .card-text {
  font-size: 0.9rem;
}

/* Asegúrate de que no haya reglas CSS que agreguen líneas debajo de los títulos */
.section-title::after {
  content: none;
}

/* Ajustes adicionales para responsividad */
@media (max-width: 200px) {
  .carousel-item {
    height: 300px; /* Ajusta la altura del carrusel en pantallas pequeñas */
  }
}



/* Contenedor para asegurar que el título está sobre la imagen */
.header-container {
  position: relative;
}

/* Estilo para el contenedor del título "Servicios de [Nombre del Servicio]" */
.service-title-container {
  position: absolute;
  top: 65%; /* Ajusta la posición vertical según sea necesario */
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center; /* Centra el texto horizontalmente */
}

/* Estilo para el título "Servicios de [Nombre del Servicio]" */
.service-titles {
  background-color: rgba(95, 7, 7, 0.5); /* Fondo semitransparente para mejorar la legibilidad */
  padding: 95px; /* Ajusta el padding según tu preferencia */
  font-size: 3.5rem; /* Ajusta el tamaño de la fuente según tus necesidades */
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Media Query para dispositivos móviles */
@media (max-width: 768px) {
  .service-titles {
    font-size: 2rem; /* Ajusta el tamaño de la fuente para pantallas más pequeñas */
    padding: 40px 20px; /* Ajusta el padding para que se vea bien en dispositivos móviles */
  }

  .service-titles-container {
    top: 50%; /* Ajusta la posición vertical para móviles */
  }
}

@media (max-width: 480px) {
  .service-titles {
    font-size: 1.6rem; /* Ajusta aún más el tamaño de la fuente para pantallas muy pequeñas */
    padding: 100px 46px; /* Ajusta el padding para dispositivos muy pequeños */
  }

  .service-titles-container {
    top: 66%; /* Ajusta la posición vertical para dispositivos muy pequeños */
  }
}