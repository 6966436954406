/* Estilos para el carrusel de servicios */
.services-title {
  font-size: 2rem; /* Tamaño de fuente */
  color: #28a745; /* Color verde */
  font-weight: bold; /* Negrita */
  margin: 0 0 2.5rem 0;  /* Sin margen */
}

.card-custom {
  margin-bottom: 1.5rem; /* Espacio entre las tarjetas */
  transition: transform 0.3s ease; /* Transición suave */
}

.card-custom img {
  border-bottom: 5px solid #28a745;
  width: 100%; /* Asegura que la imagen ocupe todo el ancho del contenedor */
  height: 200px; /* Ajusta la altura para que todas las imágenes tengan el mismo tamaño */
  object-fit: cover; /* Asegura que la imagen cubra el área definida sin distorsionarse */
}

.card-custom:hover {
  transform: translateY(-20px); /* Mueve la tarjeta hacia arriba */
}

.services-button-align-right {
  align-self: flex-end;
}

/* Asegura que el cuerpo y el html cubran toda la ventana y eliminen cualquier margen/padding */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Evita el desplazamiento horizontal */
}


/* Asegura que el contenedor del carrusel cubra toda la ventana */
.full-width-carousel {
  margin: 0;
  padding: 0;
  width: 100vw; /* Asegura que el contenedor cubra toda la anchura de la ventana */
  overflow: hidden;
}

/* Asegura que cada elemento del carrusel cubra toda la ventana */
.full-width-carousel .carousel-item {
  position: relative;
  width: 100vw; /* Asegura que cada elemento del carrusel cubra toda la anchura de la ventana */
  height: 100vh; /* Hace que las imágenes cubran toda la altura de la ventana */
}

/* Ajusta las imágenes del carrusel para que cubran toda la ventana */
.full-width-carousel .carousel-item img {
  width: 100vw; /* Asegura que las imágenes cubran toda la anchura de la ventana */
  height: 100vh; /* Hace que las imágenes cubran toda la altura de la ventana */
  object-fit: cover; /* Asegura que la imagen cubra todo el contenedor sin distorsionarse */
}

/* Asegura que el overlay cubra toda la imagen */
.text-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Fondo semitransparente */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.text-overlay h1 {
  font-size: 3.5rem; /* Tamaño del texto */
  margin-bottom: -5rem; /* Espacio entre el texto y el botón */
  color: #fff; /* Color del texto */
}

.text-overlay button {
  margin-top: 10rem; /* Espacio entre el texto y el botón */
  font-size: 1.5rem;
  padding: 10px 20px; /* Tamaño del botón */
  background-color: #28a745; /* Color del botón */
  border: none;
  border-radius: 7px;
  transition: background-color 0.1s ease;
}

.text-overlay button:hover {
  background-color: #218838; /* Color del botón al pasar el cursor */
}

/* somos... */
.who-we-are-section {
  background-color: #ffffff; /* Fondo blanco para un aspecto limpio */
  padding: 60px 30px; /* Espaciado alrededor de la sección */
  border-radius: 12px; /* Bordes redondeados */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Sombra más prominente */
  transition: transform 0.3s ease-in-out; /* Transición suave para el efecto hover */
}

.who-we-are-section:hover {
  transform: scale(1.07); /* Efecto de agrandamiento al pasar el cursor */
}

.section-title {
  font-size: 2.5rem; /* Tamaño de fuente más grande */
  color: #28a745; /* Color del texto */
  margin-bottom: 20px; /* Espacio debajo del título */
  font-weight: bold; /* Negrita */
  text-align: left; /* Alineación a la izquierda */
}

.section-text {
  font-size: 1.2rem; /* Tamaño de fuente más grande para el párrafo */
  line-height: 1.8; /* Altura de línea para mejorar la legibilidad */
  color: #000000; /* Color gris oscuro para el texto */
  text-align: justify; /* Justificar texto */
  margin-bottom: 20px; /* Espacio debajo del texto */
  font-family: 'Roboto', sans-serif; /* Fuente moderna y legible */
}

@keyframes rotate {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}
.who-we-are-image {
  max-width: 100%; /* Asegura que la imagen no exceda el ancho del contenedor */
  border-radius: 200px; /* Bordes redondeados para la imagen */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra sutil */
  animation: rotate 10s linear infinite; /* Aplica la animación */
}


/* Servicios profesionales que ofrecemos*/

.carousel-control-prev,
.carousel-control-next {
  width: 40px; /* Ajusta el ancho para que solo cubra la flecha */
  height: 40px; /* Ajusta la altura para que solo cubra la flecha */
  padding: 0; /* Elimina el espacio adicional alrededor de la flecha */
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 30px; /* Tamaño de la flecha */
  height: 30px; /* Tamaño de la flecha */
  background-size: 100%; /* Asegura que la flecha ocupe todo el icono */
  background-color: rgba(0, 0, 0, 0.5); /* Opcional: fondo oscuro para visibilidad */
  border-radius: 50%; /* Hace la flecha circular */
  margin-top: 180px;
}

.carousel-control-prev {
  left: 15px; /* Ajusta la posición de la flecha izquierda */
}

.carousel-control-next {
  right: 15px; /* Ajusta la posición de la flecha derecha */
}

/* LISTA */
/* Section styles */
.why-choose-us {
  background-color: #ffffffcb; /* Light background for contrast */
  padding: 40px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Section title styles */
.section-title {
  font-size: 2.5rem;
  color: #28a745;
  margin-bottom: 30px;
  font-weight: bold;
  position: relative;
  display: inline-block;
}

.section-title::after {
  content: '';
  width: 50px;
  height: 3px;
  background-color: #28a745;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

/* List styles */
.reasons-list {
  list-style-type: none;
  padding: 0;
  font-size: 1.2rem;
  line-height: 1.8;
  color: #333;
}

.reasons-list li {
  margin-bottom: 15px;
  position: relative;
  padding-left: 25px;
}

.reasons-list li::before {
  content: '✔';
  color: #28a745;
  font-weight: bold;
  position: absolute;
  left: 0;
  top: 0;
}

/* Responsive styles */
@media (max-width: 768px) {
  .section-title {
    font-size: 1rem;
  }

  .reasons-list {
    font-size: 1rem;
  }
}

/* PERSONAS */
.team-title {
  font-size: 2.5rem; /* Tamaño de fuente más grande */
  color: #28a745; /* Color del texto */
  margin-bottom: 40px; /* Espacio debajo del título */
  font-weight: bold; /* Negrita */
  text-align: center; /* Alineación centrada */
}

.team-card {
  background-color: #ffffff; /* Fondo blanco para las tarjetas */
  border-radius: 12px; /* Bordes redondeados */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra sutil */
  padding: 20px; /* Espaciado interno */
  text-align: center; /* Alineación centrada */
  transition: transform 0.3s ease-in-out; /* Transición suave */
}

.team-card:hover {
  transform: translateY(-20px); /* Efecto de elevación al pasar el cursor */
}

.team-image {
  width: 150px; /* Ancho fijo para todas las imágenes */
  height: 150px; /* Altura fija para todas las imágenes */
  object-fit: cover; /* Asegura que la imagen cubra el área definida sin distorsionarse */
  border-radius: 40%; /* Bordes redondeados */
  margin: 0 auto 20px auto; /* Centro horizontalmente con margen inferior */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra sutil */
}
.rotate-on-hover {
  transition: transform 0.6s ease-in-out; /* Transición suave para la rotación */
}

.rotate-on-hover:hover {
  transform: rotate(360deg); /* Rotación completa al pasar el cursor */
}
.team-name {
  font-size: 1.5rem; /* Tamaño de fuente */
  color: #28a745; /* Color verde */
  margin-bottom: 10px; /* Espacio debajo del nombre */
}

.team-position {
  font-size: 1.2rem; /* Tamaño de fuente */
  color: #6c757d; /* Color gris */
  margin-bottom: 20px; /* Espacio debajo de la posición */
}

.team-description {
  font-size: 1rem; /* Tamaño de fuente */
  color: #000000; /* Color del texto */
  text-align: justify; /* Justificación del texto */
}

/* CLIENTES */
.clients-title {
  font-size: 2rem;
  color: #28a745;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.splide__slide img {
  width: 100px;
  height: auto;
}


