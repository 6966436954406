/* ANIMACION */





.whatsapp-float-button {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25D366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 1000;
  border: none;
  cursor: pointer;
  animation: bounce 2s infinite; /* Añadir esta línea para la animación */
}

.whatsapp-float-button img {
  width: 100%;
  height: 83%;
  padding: 8%;
}

.whatsapp-chat {
  position: fixed;
  bottom: 30px;
  right: 35px;
  width: 350px;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  border-radius: 10px;
  background: #fff;
  display: none;
  z-index: 2000;
}

.whatsapp-chat.open {
  display: block;
}

.whatsapp-header {
  background: #25D366;
  color: #fff;
  padding: 10px;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.whatsapp-header .close-button {
  background: none;
  border: none;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}

.whatsapp-body {
  padding: 15px;
}

.whatsapp-footer {
  padding: 10px;
  text-align: right;
}

.whatsapp-button {
  background: #25D366;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

/* Animación para el icono de WhatsApp */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-50px);
  }
  60% {
    transform: translateY(-20px);
  }
}
