/* src/styles/header.css */

.hero-section {
  position: relative;
  text-align: center;
  color: white;
  margin-bottom: 60px;
  height: 350px; /* Ajusta la altura según tus necesidades */
  background-size: cover;
  background-position: center 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  -bs-gutter-x: 1.5rem;
}
