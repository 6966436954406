/* src/styles/navbar.css */
.custom-navbar {
  padding: 10px 0; /* Aumenta el padding para hacerlo más alto */
  background-color: #42e887; 
}

.logo-img {
  height: 80px; /* Aumenta la altura del logo */
  width: auto;
}

.brand-name {
  font-size: 1.8rem; /* Aumenta el tamaño del texto del nombre de la marca */
  margin-left: 10px;
}

.navbar-nav .nav-link {
  margin-left: 15px;
  margin-right: 15px;
  font-size: 1.2rem; /* Aumenta el tamaño del texto de los enlaces */
  color: #333;
}

.navbar-nav .nav-link:hover {
  color: #007bff;
}

body {
  padding-top: 80px; /* Asegúrate de que el contenido no quede oculto detrás del navbar */
}

.navbar {
  width: 100%;
  position: relative;
  z-index: 1;
}

.navbar .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-collapse {
  flex-grow: 1;
  justify-content: flex-end;
}
